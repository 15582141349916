<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("XB_MyCollection") }}
      <!-- 我的收藏 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 类别 -->
      <ListFilter
        only
        :name="$t('XB_Category')"
        :dataSource="reviewStatus"
        @onChange="switchTypes"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="key"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
            <!-- 请输入名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="edit-wrap">
      <span class="red" @click="toDel" v-show="isEdit">
        {{ $t("CM_Delete")
        }}<!-- 删除 -->
      </span>
      <span class="blue" @click="isEdit = !isEdit">
        {{ isEdit ? $t("BN_Comm_Completed") : $t("BN_Comm_Edit")
        }}<!-- "完成" : "编辑" -->
      </span>
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div class="content card">
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <div class="data-card card" @click="jump(item)">
                <div class="tag">
                  <span class="blue" v-if="item.resourceType == 1">
                    {{ $t("Pub_Data")
                    }}<!-- 知识 -->
                  </span>
                  <span class="green" v-else-if="item.resourceType == 2">
                    {{ $t("CM_Curriculum")
                    }}<!-- 课程 -->
                  </span>
                  <span class="red" v-else-if="item.resourceType == 35">
                    {{ $t("cm_package")
                    }}<!-- 培训包 -->
                  </span>
                </div>
                <div class="cover" :style="{ backgroundImage: 'url(' + coverImg + ')',backgroundSize:'contain'}">
                  <img
                    :src="
                      item.coverImg ||
                      require(`@/assets/image/mine/collect_cover.png`)
                    "
                    :style="{maxWidth: '100%',width:'auto', background: '#fff'}"
                    alt="cover"
                  />
                </div>
                <div class="check" v-show="isEdit">
                  <CheckOutlined v-show="item.isDel" />
                </div>
                <div class="detail">
                  <h5>
                    <a-popover trigger="hover">
                      <template #content>
                        <div class="popover-content">
                          {{ item.title }}
                        </div>
                      </template>
                      {{ item.title }}
                    </a-popover>
                  </h5>
                  <div class="row mt12">
                    {{ $t("Lab_MyFavorite_FavoriteDate") }}：{{
                      dateFormat(item.createTime)
                    }}
                    <!-- 收藏时间 -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, getCurrentInstance, createVNode } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ls from "@/utils/local-storage";
import { getFavoriteList, delsFavorite } from "@/api/user";
import { dateFormat } from "@/utils/tools";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import ListFilter from "@/components/new/filter/ListFilter.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      isEdit: false,
      reviewStatus: [],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      key: "",
      types: [],
      companyInfo: ls.get("companyInfo") || {},
      listLoading: true,
      coverImg: require("@/assets/image/coverBg.png"),
    });

    if (state.companyInfo.menu.includes("knowledge")) {
      state.reviewStatus.push({ id: 1, name: $t("Pub_Tab_Knowledge") });
      // 知识
    }
    if (state.companyInfo.menu.includes("course")) {
      state.reviewStatus.push({ id: 2, name: $t("CM_Curriculum") });
      // 课程
    }
    if (state.companyInfo.menu.includes("package")) {
      state.reviewStatus.push({ id: 35, name: $t("cm_package") });
      // 培训包
    }

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      getFavoriteList({
        page: state.currentPage,
        pageSize: 15,
        key: state.key,
        types: state.types,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const jump = (item) => {
      if (!state.isEdit) {
        let newWindow = store.getters.companyInfo.useNewWindow == 1;
        if (item.resourceType == 1) {
          if (newWindow) {
            window.open(`/doc/detail?ddtab=true&id=${item.resourceId}`);
          } else {
            router.push({
              path: "/doc/detail",
              query: {
                id: item.resourceId,
              },
            });
          }
        } else if (item.resourceType == 2) {
          if (newWindow) {
            window.open(`/course/detail?ddtab=true&id=${item.resourceId}`);
          } else {
            router.push({
              path: "/course/detail",
              query: {
                id: item.resourceId,
              },
            });
          }
        } else if (item.resourceType == 35) {
          if (newWindow) {
            window.open(`/package/detail?ddtab=true&id=${item.resourceId}`);
          } else {
            router.push({
              path: "/package/detail",
              query: {
                id: item.resourceId,
              },
            });
          }
        } else if (item.resourceType == 37) {
          if (newWindow) {
            window.open(`/newstaff/detail?ddtab=true&id=${item.resourceId}`);
          } else {
            router.push({
              path: "/newstaff/detail",
              query: {
                id: item.resourceId,
              },
            });
          }
        }
      } else {
        item.isDel = !item.isDel;
      }
    };

    const toDel = () => {
      let ids = [];
      state.dataList.map((item) => {
        if (item.isDel) {
          ids.push({
            id: item.resourceId,
            type: item.resourceType,
          });
        }
      });
      if (ids.length) {
        Modal.confirm({
          title: () => $t("LB_Group_CancelCollection"),
          // 确定取消收藏吗？
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            delsFavorite(ids).then((res) => {
              proxy.$message.success($t("CM_Success"));
              // 操作成功！
              state.isEdit = false;
              reloadList();
            });
          },
        });
      } else {
        proxy.$message.warning($t("please_select_data"));
        // 请选择数据
      }
    };

    const switchTypes = (arr) => {
      state.types = arr[0] === 0 ? [] : arr;
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      ...toRefs(state),
      switchTypes,
      pageChange,
      dateFormat,
      jump,
      toDel,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
@import "./card.less";
.edit-wrap {
  padding: 24px 0 12px;
  line-height: 24px;
  font-size: 16px;
  .mixinFlex(flex-end; center);
  span {
    cursor: pointer;
    margin-left: 16px;
  }
  .blue {
    color: #377dff;
  }
  .red {
    color: #e8673e;
  }
}
.data-card {
  .check {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    .mixinFlex(center; center);
    font-size: 18px;
    color: #fff;
  }
}
</style>
